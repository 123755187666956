import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
  p, ul, ol {
    max-width: 900px;
    margin-right: auto;
    margin-left: auto;
    text-align: center;
  }
`;
