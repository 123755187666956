import React from 'react';
import styled from 'styled-components';
import { formatRelative } from 'date-fns';
import { de } from 'date-fns/locale';

import { Link } from 'gatsby';
import dateService from '../../services/date.service';
import { EmergencyService } from '../../models/emergency-service.model';
import { grey } from '../../utils/colors';

interface EmergencyServiceProps {
  service: EmergencyService;
  upcoming?: boolean;
  link?: boolean;
}

export default ({
  service: { apotheke, untilDate, fromDate },
  upcoming = false,
  link = false,
}: EmergencyServiceProps) => {
  const willChangeDate = upcoming ? fromDate : untilDate;
  const content = (
    <>
      <ServiceTitle>{apotheke.meta.apo_name}</ServiceTitle>
      <div>Tel. {apotheke.meta.apo_tel}</div>
      <div
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{ __html: apotheke.meta.apo_adresse }}
      />
    </>
  );
  return (
    <ServiceContainer>
      {link ? (
        <ServiceLink to="/notdienst/">{content}</ServiceLink>
      ) : (
        <div>{content}</div>
      )}
      <ServiceChangeNotice>
        {upcoming ? 'beginnt' : 'endet'}{' '}
        {formatRelative(willChangeDate, dateService.date(), { locale: de })} Uhr
      </ServiceChangeNotice>
    </ServiceContainer>
  );
};

const ServiceContainer = styled.div`
  margin-bottom: 20px;
  & p {
    margin-bottom: 0px;
  }
  text-align: center;
`;

const ServiceTitle = styled.h5`
  margin-bottom: 10px;
`;

const ServiceLink = styled(Link)`
  color: inherit;
  text-decoration: none;
  &:hover,
  &:active,
  &:focus {
    text-decoration: underline;
  }
`;

const ServiceChangeNotice = styled.small`
  color: ${grey};
  font-size: 12px;
`;
