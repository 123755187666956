import React, { useState, useEffect, ReactNode } from 'react';

import { OpeningHours } from '../models/opening-hours.model';
import { EmergencyResponse } from '../models/emergency-service.model';
import openingHoursService from '../services/opening-hours.service';
import emergencyServicesService from '../services/emergency-services.service';
import onError from '../utils/on-error';

export interface AppContextValue {
  openingHours?: OpeningHours;
  emergencyServices?: EmergencyResponse;
}
const AppContext = React.createContext<AppContextValue>({});

export const AppContextProvider = ({ children }: { children: ReactNode }) => {
  const [openingHours, setOpeningHours] = useState<OpeningHours>();
  const [emergencyServices, setEmergencyServices] =
    useState<EmergencyResponse>();

  useEffect(() => {
    openingHoursService.subscribeToHours(setOpeningHours, onError);
    emergencyServicesService.subscribeToEmergency(
      setEmergencyServices,
      onError,
    );
  }, []);

  return (
    <AppContext.Provider
      value={{
        openingHours,
        emergencyServices,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export default AppContext;
