export enum OpeningHoursStatus {
  Closed = 0,
  Open = 1,
  EmergencyService = 2,
}

export interface OpeningHoursRaw {
  status: OpeningHoursStatus;
  from: number;
  until: number;
  refresh: number;
  timestamp: number;
}

export interface OpeningHours extends OpeningHoursRaw {
  fromDate: Date;
  untilDate: Date;
  refreshDate: Date;
  timestampDate: Date;
}
