import { isAfter, parseISO } from 'date-fns';
import dateService from './date.service';

export default {
  cache<T>(
    storageKey: string,
    getExpirationDate: (value: T) => Date,
    getValue: () => Promise<T>,
    parseDateKeys: string[] = [],
  ): Promise<T> {
    return (
      Promise.resolve()
        // get value from cache
        .then(() => sessionStorage.getItem(storageKey))
        // check if value is defined
        .then((cache) => {
          if (!cache) throw Error('cache object is not defined');
          return cache;
        })
        // parse json
        .then((cache: string) =>
          JSON.parse(cache, (key: string, value: any) => {
            if (parseDateKeys.includes(key)) return parseISO(value);
            return value;
          }),
        )
        // check is value is expired
        .then((result: T) => {
          if (isAfter(dateService.now(), getExpirationDate(result)))
            throw Error('cache object is expired');
          return result;
        })
        // if cache didn't work
        .catch(() => {
          // fetch value
          return getValue().then((value) => {
            // and update cache
            sessionStorage.setItem(storageKey, JSON.stringify(value));
            return value;
          });
        })
    );
  },
};
