import React from 'react';
import styled from 'styled-components';
import { differenceInMinutes } from 'date-fns';
import { Aid } from 'grommet-icons';

import AppContext, { AppContextValue } from '../AppContext';
import dateService from '../../services/date.service';
import { red, green, orange } from '../../utils/colors';
import { OpeningHoursStatus } from '../../models/opening-hours.model';

const thresholdMinutes = 60;

const OpeningHours: React.FC = () => (
  <AppContext.Consumer>
    {({ openingHours }: AppContextValue) => {
      if (!openingHours) return null;
      const minutes = differenceInMinutes(
        openingHours.untilDate,
        dateService.date(),
      );
      if (minutes < 0) return null;
      return (
        <HoursContainer>
          {openingHours.status === OpeningHoursStatus.Closed && (
            <IsClosedText>Aktuell geschlossen.</IsClosedText>
          )}
          {openingHours.status === OpeningHoursStatus.Open && (
            <IsOpenText>Aktuell geöffnet!</IsOpenText>
          )}
          {openingHours.status === OpeningHoursStatus.EmergencyService && (
            <IsOpenText>
              <IconContainer>
                <Aid color={green} size="small" />
              </IconContainer>{' '}
              Aktuell geöffnet im Notdienst!
              <IconContainer>
                <Aid color={green} size="small" />
              </IconContainer>
            </IsOpenText>
          )}
          <WillChangeSoon status={openingHours.status} minutes={minutes} />
        </HoursContainer>
      );
    }}
  </AppContext.Consumer>
);

export default OpeningHours;

interface WillChangeSoonProps {
  status: OpeningHoursStatus;
  minutes: number;
}

const WillChangeSoon = ({ status, minutes }: WillChangeSoonProps) =>
  minutes >= 0 && minutes < thresholdMinutes ? (
    <WillChangeText>
      Wir {status === OpeningHoursStatus.Closed ? 'öffnen' : 'schließen'} in{' '}
      {minutes < 5 ? 'Kürze' : `${minutes} Minuten`}.
    </WillChangeText>
  ) : null;

const HoursContainer = styled.div`
  font-size: 0.8em;
  font-weight: bold;
  text-align: center;
`;

const IsOpenText = styled.div`
  color: ${green};
`;

const IsClosedText = styled.div`
  color: ${red};
`;

const WillChangeText = styled.div`
  color: ${orange};
`;

const IconContainer = styled.span`
  margin: 0 5px;
`;
