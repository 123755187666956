import React from 'react';
import styled from 'styled-components';
import { Box } from 'grommet';
import { useStaticQuery, graphql, Link } from 'gatsby';
import { grey } from '../../utils/colors';

export default () => {
  const { allJavascriptFrontmatter } = useStaticQuery(graphql`
    query {
      allJavascriptFrontmatter(
        sort: { fields: frontmatter___footer }
        filter: { frontmatter: { footer: { ne: null } } }
      ) {
        edges {
          node {
            id
            fields {
              slug
            }
            frontmatter {
              title
              menu
              error
            }
          }
        }
      }
    }
  `);

  const footerPages = allJavascriptFrontmatter.edges.map(({ node }: any) => {
    return { title: node.frontmatter.title, slug: node.fields.slug };
  });
  return <PureFooter footerPages={footerPages} />;
};

interface PureHeaderProps {
  footerPages: {
    title: string;
    slug: string;
  }[];
}

export const PureFooter: React.FC<PureHeaderProps> = ({ footerPages }) => (
  <Footer>
    <FooterMenu>
      {footerPages.map(({ slug, title }: any) => (
        <ListElement key={slug}>
          <ListLink to={slug}>{title}</ListLink>
        </ListElement>
      ))}
    </FooterMenu>
    <p>
      Der Schutz Ihrer Daten ist uns wichtig. Deshalb verwenden wir Matomo als
      datenschutzfreundliche Alternative zu Google Analytics.
    </p>
  </Footer>
);

const Footer = styled(Box).attrs({
  elevation: 'medium',
  as: 'footer',
})`
  height: 100px;
  align-content: center;
  border-top: 1px solid lightgray;
  margin-top: 30px;
  color: ${grey};
  font-size: 12px;
`;

const FooterMenu = styled.ul`
  display: flex;
  max-width: initial;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0;
  list-style: none;
`;

const ListElement = styled.li`
  padding: 0 5px;
`;

const ListLink = styled(Link)`
  color: ${grey};
  font-size: 12px;
  text-decoration: none;
  &:hover,
  &:focus {
    text-decoration: underline;
  }
`;
