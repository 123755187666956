/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import { black } from '../utils/colors';

export default ({ to, children, ...rest }: any) => (
  <StyledLink to={to} {...rest} activeStyle={{ textDecoration: 'underline' }}>
    {children}
  </StyledLink>
);

const StyledLink = styled(Link)`
  color: ${black};
  font-weight: 600;
  text-decoration: none;
  &:hover,
  &:focus {
    text-decoration: underline;
  }
`;
