import React, { ReactElement } from 'react';
import { Thing, WithContext } from 'schema-dts';
import { Helmet } from 'react-helmet';

interface JsonLdProps<T extends Thing> {
  children: WithContext<T>;
}

type JsonLdType = <T extends Thing>(
  props: JsonLdProps<T>,
) => ReactElement<JsonLdProps<T>>;

const JsonLd: JsonLdType = ({ children }) => {
  return (
    <Helmet>
      <script type="application/ld+json">{JSON.stringify(children)}</script>;
    </Helmet>
  );
};

export default JsonLd;
