import React, { FC } from 'react';
import useMediaQuery from '../hooks/useMediaQuery';

interface IsSmallerChildProp {
  isSmaller: boolean;
}

interface IsSmallerProps {
  than: number;
  children: FC<IsSmallerChildProp>;
}

const IsSmaller: FC<IsSmallerProps> = ({ children: Children, than }) => {
  const isSmaller = !useMediaQuery(`(min-width: ${than}px)`);
  return <Children isSmaller={isSmaller} />;
};

export default IsSmaller;
