import { addMilliseconds } from 'date-fns';
import log from 'loglevel';

// min offset in s
const threshold = 30;

export default {
  offset: 0,

  /**
   * Sets a date offset given a server timestamp
   *
   * @param {number} serverTimestamp timestamp in ms
   */
  setServerOffset(serverTimestamp: number) {
    const offset = serverTimestamp - Date.now();
    // only set offset if its more than 30s
    if (offset > threshold * 1000) {
      this.offset = offset;
      log.info('set offset (ms):', offset);
    } else {
      this.offset = 0;
      log.info('offset below threshold (ms):', offset);
    }
  },
  date() {
    return addMilliseconds(new Date(), this.offset);
  },
  now() {
    return Date.now() + this.offset;
  },
};
