import React, { useEffect, useState, FC } from 'react';
import styled from 'styled-components';
import NukaCarousel from 'nuka-carousel';
import useMediaQuery from '../hooks/useMediaQuery';

interface CarouselProps {
  children: React.ReactElement[] | React.ReactElement;
  mobileBreakpoint: number;
  fullWidth?: boolean;
  zoomIn?: boolean;
  controlOverlay?: boolean;
  autoPlayInterval?: number;
}

const Carousel: FC<CarouselProps> = ({
  children,
  mobileBreakpoint = 600,
  fullWidth = false,
  controlOverlay = true,
  zoomIn = false,
  autoPlayInterval,
}: CarouselProps) => {
  const isMobile = !useMediaQuery(`(min-width: ${mobileBreakpoint}px)`);
  const autoplay = isMobile && !!autoPlayInterval;
  // fix bug: height is 0 on first render
  // https://github.com/FormidableLabs/nuka-carousel/issues/521
  useEffect(() => {
    setTimeout(() => window.dispatchEvent(new Event('resize')), 0);
  }, []);

  // manually control slide state to distinguish between mobile and desktop view
  const [slide, setSlide] = useState(0);

  const numberOfSlides = isMobile || zoomIn ? 1 : 3;
  const hasMoreSlidesThanShown =
    (children as React.ReactElement[]).length > numberOfSlides;

  return (
    <StyledCarousel
      heightMode="max"
      cellSpacing={10}
      mobileBreakpoint={mobileBreakpoint}
      fullWidth={fullWidth}
      cellAlign={hasMoreSlidesThanShown ? 'center' : 'left'}
      withoutControls={!hasMoreSlidesThanShown}
      slidesToShow={numberOfSlides}
      dragging={hasMoreSlidesThanShown}
      swiping={hasMoreSlidesThanShown}
      framePadding={controlOverlay ? '0' : '20px'}
      slideIndex={hasMoreSlidesThanShown ? slide : 0}
      afterSlide={(slideIndex) => {
        if (isMobile) setSlide(slideIndex);
      }}
      autoplay={autoplay}
      autoplayInterval={autoPlayInterval}
      wrapAround
      renderCenterLeftControls={null}
      renderCenterRightControls={null}
    >
      {children}
    </StyledCarousel>
  );
};

const StyledCarousel = styled(NukaCarousel)<{
  mobileBreakpoint: number;
  fullWidth?: boolean;
}>`
  max-width: 100%;
  max-height: 90vh;
  @media (min-width: ${(props) => props.mobileBreakpoint}px) {
    max-width: ${(props) => (props.fullWidth ? '100%' : '900px')};
    margin-right: auto;
    margin-left: auto;
  }
`;

export default Carousel;
