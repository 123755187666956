export const white = '#fff';
export const greyLight = '#d3d3d3';
export const grey = '#757575';
export const black = '#000';

export const redLight = '#ff563e';
export const red = '#e30612';
export const redDark = '#a80000';

export const green = '#008000';
export const orange = '#ffa500';
export const yellow = '#ebbc00';

export default {
  white,
  greyLight,
  grey,
  black,
  redLight,
  red,
  redDark,
  green,
  orange,
  yellow,
};
