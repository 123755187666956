import React from 'react';
import styled from 'styled-components';
import Link from '../Link';

export interface MenuProps {
  pages: {
    title: string;
    slug: string;
  }[];
}

const Menu: React.FC<MenuProps> = ({ pages }) => (
  <StyledNav>
    <MenuList>
      {pages.map((page) => (
        <ListElement key={page.slug}>
          <Link to={page.slug}>{page.title}</Link>
        </ListElement>
      ))}
    </MenuList>
  </StyledNav>
);

export default Menu;

const StyledNav = styled.nav`
  margin: 5px;
`;

const MenuList = styled.ul`
  display: flex;
  max-width: initial;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0;
  list-style: none;
`;

const ListElement = styled.li`
  padding: 0 5px;
`;
