import { differenceInMilliseconds } from 'date-fns';
import log from 'loglevel';
import dateService from './date.service';

export default {
  refresh<T>(getValue: () => Promise<T>, getRefreshDate: (value: T) => Date) {
    const observable = (
      next: (value: T) => void,
      error: (reason: string) => void,
    ): Promise<void> => {
      // get value
      return (
        getValue()
          .then((value: T) => {
            // notify subscriber
            next(value);
            // calc ms until refresh
            const refreshInMs = differenceInMilliseconds(
              getRefreshDate(value),
              dateService.date(),
            );
            log.info(`refresh in ${refreshInMs}ms (min: 30000)`);
            // setup refresh
            setTimeout(() => {
              observable(next, error);
            }, Math.max(30000, refreshInMs));
          })
          // notify subscriber on errors
          .catch(error)
      );
    };
    return observable;
  },
};
