import { useState, useEffect } from 'react';

export default (query: string) => {
  const [isMatch, setIsMatch] = useState(false);

  useEffect(() => {
    const updateMatch = () => setIsMatch(window.matchMedia(query).matches);

    updateMatch();

    // safari still does not support .addEventListener on matchMedia (see: https://github.com/mdn/sprints/issues/858)
    const useModernListener: boolean =
      !!window.matchMedia(query).addEventListener;

    if (useModernListener) {
      window.matchMedia(query).addEventListener('change', updateMatch);

      return () => {
        window.matchMedia(query).removeEventListener('change', updateMatch);
      };
    }

    window.matchMedia(query).addListener(updateMatch);
    return () => {
      window.matchMedia(query).removeListener(updateMatch);
    };
  }, [query]);

  return isMatch;
};
