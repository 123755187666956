import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import Header, { headerHeightTop } from './Header';

export default () => {
  const checkIsTop = () => !(window.pageYOffset > 0);
  const [isTop, setIsTop] = useState(true);
  useEffect(() => {
    const updateIsTop = () => setIsTop(checkIsTop());
    window.addEventListener('scroll', updateIsTop);

    // cleanup
    return () => window.removeEventListener('scroll', updateIsTop);
  }, [isTop]);
  return (
    <HeaderContainer>
      <Header isTop={isTop} />
    </HeaderContainer>
  );
};

const HeaderContainer = styled.div`
  z-index: 2;
  height: ${headerHeightTop}px;
  flex-shrink: 0;
`;
