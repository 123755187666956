import { red } from './colors';

export default {
  global: {
    colors: {
      brand: 'black',
      focus: red,
    },
  },
};
