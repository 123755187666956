import React from 'react';
import styled from 'styled-components';
import { Box } from 'grommet';
import heading from '../../assets/heading-no-details.svg';
import logo from '../../assets/a-mit-hirsch.svg';
import { white } from '../../utils/colors';

export const headerHeight = 50;
export const headerHeightTop = 80;

interface HeaderProps {
  isTop?: boolean;
}

export default ({ isTop }: HeaderProps = { isTop: false }) => {
  return (
    <StyledHeader
      direction="row"
      align="end"
      gap="10px"
      pad="5px"
      elevation="medium"
      justify="center"
      isTop={isTop}
    >
      <LogoImg src={logo} alt="Logo der Hirsch-Apotheke" />
      <TitleImg src={heading} alt="Hirsch-Apotheke" />
    </StyledHeader>
  );
};

const StyledHeader = styled(Box)<HeaderProps>`
  position: fixed;
  width: 100%;
  height: ${({ isTop }) => (isTop ? headerHeightTop : headerHeight)}px;
  align-items: center;
  background-color: ${white};
  transition: height 0.3s ease;
`;

const LogoImg = styled.img`
  max-height: 100%;
  padding-top: 5px;
  margin: 0;
`;

const TitleImg = styled.img`
  max-height: 70%;
  padding-top: 5px;
  margin: 0;
`;
