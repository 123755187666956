import React from 'react';
import { Button } from 'grommet';
import { IconProps } from 'grommet-icons';
import styled from 'styled-components';

interface ActionButtonProps {
  title: string;
  href: string;
  hoverColor: string;
  Icon: React.ComponentType<IconProps>;
}

interface HoverProps {
  // eslint-disable-next-line react/no-unused-prop-types
  hover: boolean;
  // eslint-disable-next-line react/no-unused-prop-types
  focus: boolean;
}

export default ({ title, href, hoverColor, Icon }: ActionButtonProps) => (
  <StyledButton plain title={title} href={href}>
    {({ hover, focus }: HoverProps) => (
      <Icon color={hover || focus ? hoverColor : undefined} a11yTitle={title} />
    )}
  </StyledButton>
);

const StyledButton = styled(Button)`
  margin: 15px;
  &:hover svg,
  &:focus svg,
  &:active svg {
    filter: drop-shadow(0 1px 1px #dddddd);
  }
`;
